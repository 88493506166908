import React from "react";
import { Trans } from "react-i18next";

const componentName = "About";

const About = ({ t }) => {
  return (
    <section className={componentName} id="home">
      <h2>{t("sections.about.title")}</h2>
      <p>
        <Trans
          i18nKey="sections.about.p1"
          components={{ strong: <strong /> }}
        />
      </p>
      <p>
        <Trans
          i18nKey="sections.about.p2"
          components={{ strong: <strong /> }}
        />
      </p>
      <p>
        <Trans
          i18nKey="sections.about.p3"
          components={{ strong: <strong /> }}
        />
      </p>
      <p>
        <Trans
          i18nKey="sections.about.p4"
          components={{ strong: <strong /> }}
        />
      </p>
      <p>
        <Trans
          i18nKey="sections.about.p5"
          components={{ strong: <strong /> }}
        />
      </p>
      <p>
        <Trans
          i18nKey="sections.about.p6"
          components={{ strong: <strong /> }}
        />
      </p>
      <p>
        Tým <strong>Donutku</strong>
      </p>
    </section>
  );
};

export default About;
