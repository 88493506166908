import React, { useState } from "react";

const componentName = "Gallery";

const galleryItems = [
  {
    img: "images/gallery/akce-bazen.jpg",
    title: "Bazén Český Těšín",
    date: "Léto 2024",
    link: "https://www.instagram.com/donutky.cz/",
    alt: "Stánek s mini koblížky u bazénu v Českém Těšíně během léta 2024",
  },
  {
    img: "images/gallery/gorol.jpg",
    title: "Gorolski Święto",
    date: "03. 08. 2024",
    link: "https://www.instagram.com/donutky.cz/",
    alt: "Stánek s mini koblížky na festivalu Gorolski Święto 3. srpna 2024",
  },
  {
    img: "images/gallery/akce-milikov.jpg",
    title: "Burácení pod Kozubovou",
    date: "01. 06. 2024",
    link: "https://www.instagram.com/donutky.cz/",
    alt: "Stánek s mini koblížky na akci Burácení pod Kozubovou 1. června 2024",
  },
  {
    img: "images/gallery/akce-baska.jpg",
    title: "Firemní akce Baška",
    date: "31. 05. 2024",
    link: "https://www.instagram.com/donutky.cz/",
    alt: "Stánek s mini koblížky na firemní akci v Bašce 31. května 2024",
  },
  {
    img: "images/gallery/akce-hutnik.jpg",
    title: "Hutnický den",
    date: "18. 05. 2024",
    link: "https://www.instagram.com/donutky.cz/",
    alt: "Stánek s mini koblížky na Hutnickém dni 18. května 2024",
  },
  {
    img: "images/gallery/akce-karvina.jpg",
    title: "Gastro den Karviná",
    date: "2023",
    link: "https://www.instagram.com/donutky.cz/",
    alt: "Stánek s mini koblížky na Gastro dni v Karviné v roce 2023",
  },
  {
    img: "images/gallery/akce-dobra.jpg",
    title: "Doberská pouť",
    date: "22. 04. 2023",
    link: "https://www.instagram.com/donutky.cz/",
    alt: "Stánek s mini koblížky na Doberské pouti 22. dubna 2023",
  },
  {
    img: "images/gallery/akce-hokej.jpg",
    title: "Hokej extraliga",
    date: "2023",
    link: "https://www.instagram.com/donutky.cz/",
    alt: "Stánek s mini koblížky na utkání hokejové extraligy v roce 2023",
  },
];

const Gallery = ({ t }) => {
  const [visibleItems, setVisibleItems] = useState(6);

  const handleShowMore = () => {
    setVisibleItems(galleryItems.length);
  };

  const handleShowLess = () => {
    setVisibleItems(6);
  };

  return (
    <section className={componentName} id="gallery">
      <h2>{t("sections.gallery.title")}</h2>

      <div className={`${componentName}__content`}>
        {galleryItems.slice(0, visibleItems).map((item, index) => (
          <div key={index} className={`${componentName}__item`}>
            <a target="_blank" href={item.link} rel="noreferrer">
              <img src={item.img} alt={item.title} />
              <div className={`${componentName}__item-description`}>
                <h3 className={`${componentName}__item-title`}>{item.title}</h3>
                <h3 className={`${componentName}__item-date`}>{item.date}</h3>
              </div>
            </a>
          </div>
        ))}
      </div>
      {visibleItems < galleryItems.length ? (
        <button onClick={handleShowMore}>
          {t("sections.gallery.buttonOther")}
        </button>
      ) : (
        <button onClick={handleShowLess}>
          {t("sections.gallery.buttonLess")}
        </button>
      )}
    </section>
  );
};

export default Gallery;
