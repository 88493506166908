import React from "react";
import Navbar from "./Navbar";

const componentName = "Footer";

const Footer = () => {
  return (
    <footer className={componentName} id="contact">
      <div className={`${componentName}__content`}>
        <div className={`${componentName}__contact`}>
          <h3>Kontakt</h3>
          <p>
            <a href="tel: +420 774 366 540"> +420 774 366 540</a>
          </p>
          <p>
            <a href="mailto:donutkyprodej@gmail.com">donutkyprodej@gmail.com</a>
          </p>
        </div>
        <div className={`${componentName}__logo`}>
          <h3>Donutky.cz</h3>
        </div>
        <div className={`${componentName}__socials`}>
          <a href="https://www.instagram.com/donutky.cz/">
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M3 8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8Zm5-3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H8Zm7.597 2.214a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z"
                clip-rule="evenodd"
              />
            </svg>
          </a>
        </div>
      </div>
      <div className={`${componentName}__rights`}>
        <span>Donutky.cz - © 2024</span>
      </div>
    </footer>
  );
};

export default Footer;
