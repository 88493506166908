import React, { useState } from "react";

import "./style.scss";

import Header from "./components/Header";
import Footer from "./components/Footer";

import Contact from "./sections/Contact";
import Gallery from "./sections/Gallery";
import About from "./sections/About";

import { useTranslation } from "react-i18next";

function App() {
  const { t, i18n } = useTranslation();
  const [navbarMenu, setNavbarMenu] = useState([
    {
      key: "home",
      link: "#home",
      active: false,
    },
    {
      key: "gallery",
      link: "#gallery",
      active: false,
    },
    {
      key: "contact",
      link: "#contact",
      active: false,
    },
  ]);
  const [language, setLanguage] = useState("cs");

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
  };

  console.log(navbarMenu);
  return (
    <div className="App">
      <Header
        navbarMenu={navbarMenu}
        changeLanguage={changeLanguage}
        setNavbarMenu={setNavbarMenu}
        t={t}
      />
      <main className="Main">
        <img
          className="Main__background"
          src="images/bg-crema.jpg"
          alt="logo"
        />
        <div className="wrapper">
          <About t={t} />
          <Gallery t={t} />
          {/*<Contact t={t} />*/}
        </div>
        <div className="Donuts">
          <div className="Donut">🍩</div>
          <div className="Donut">🍩</div>
          <div className="Donut">🍩</div>
          <div className="Donut">🍩</div>
          <div className="Donut">🍩</div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default App;
