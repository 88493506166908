import React from "react";
import Navbar from "./Navbar";

const componentName = "Header";

const Header = ({ navbarMenu, setNavbarMenu, changeLanguage, t }) => {
  return (
    <header className={componentName}>
      <img
        className={`${componentName}__background`}
        src="/images/bg-choco.png"
        alt="logo"
      />
      <Navbar
        t={t}
        navbarMenu={navbarMenu}
        changeLanguage={changeLanguage}
        setNavbarMenu={setNavbarMenu}
      />
    </header>
  );
};

export default Header;
